td {
    padding: 1rem;
    border-radius: 0.5rem;
    color: #323232;
    background-color: #fff;
}

th {
    padding: 1rem;
    background-color: #323232;
    color: #fff;
}
