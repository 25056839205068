@media only screen and (max-width: 600px) {
    tr, p {
        font-size: 12px;
    }
    #blockchainid {
        width: 17rem; float: left;
        
    }

    .mediaquerydesign {
        width: 12.5rem; float: left;
        word-wrap: break-word;
    }

    .mediaQueryBorder {
        padding-top: 1rem;
        border: 1px solid black;
    }

    .loaderclass {
        margin-left: 10rem;
    }
}